<template lang="html">
  <div class="image" v-if="initialData.image">
    <img
      :src="initialData.image.sizes['medium_large']"
      :alt="initialData.image.alt"
    />
  </div>
</template>

<script>
export default {
  name: 'Image',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.image {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }
}
</style>
